import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Bolet amb olor de patata crua o rave que té un capell de 6 a 9 cm de diàmetre inicialment arrodonit, carnós, per acabar obert i de color variable com groc blanquinós, groc llimona, groc verdós o blanc. Està cobert per esquames blanques que es separen fàcilment. Davall aquest, les làmines són blanques o groc fluix, amples i separables. Les espores són també blanques en massa, subgloboses o allargades de 8-11 x 7-9 cm. El peu és cilíndric, blanc, amb un anell membranós, persistent, alt i un poc estriat. Acaba en forma de bulb gros, globós i amb restes de volva.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      